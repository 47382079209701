.create_address_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #f5efef;
    overflow-y: auto;
    z-index: 601;
}

.create_address_modal .header {
    width: 100%;
    height: 70px;
    background-color: #090e3c;
    display: flex;
    align-items: center;
    padding: 5px 10%;
}

@media (max-width: 996px) {
    .create_address_modal .header {
        height: 60px;
        padding: 5px 5%;
    }    
}

.create_address_modal .header .logo {
    height: 80%;
}

.create_address_modal .header .logo img {
    height: 100%;
}

.create_address_modal .body {
    padding: 50px 20%;
}

@media (max-width: 996px) {
    .create_address_modal .body {
        padding: 30px 5%;
    }
}

.create_address_modal .body > h2 {
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 10px;
}

.create_address_modal .body .form_container {
    width: 100%;
    padding: 30px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.12);
}

.create_address_modal .body .form_container .fancy_input {
    margin-bottom: 10px;
}

.create_address_modal .body .form_container .fancy_input input {
    padding: 6px 15px;
}

.create_address_modal .body .form_container .fancy_input select {
    padding: 6px 15px;
}

.create_address_modal .body .form_container .address_num {
    width: 250px;
}

.create_address_modal .body .form_container .num_separator {
    align-self: center;
    padding-top: 14px;
}

.create_address_modal .body .form_container .loader {
    margin-top: 30px;
}

.create_address_modal .body .form_container .actions {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.create_address_modal .body .form_container .actions .btns button {
    margin-left: 10px;
}

@media (max-width: 996px) {
    .create_address_modal .body .form_container .address_container {
        flex-wrap: wrap;
    }
    
    .create_address_modal .body .form_container .address_container .street_type {
        min-width: 100%;
        flex-basis: 100%;
    }
    
    .create_address_modal .body .form_container .address_container .fancy_input {
        width: 100%;
        flex-basis: 25%;
    }
}
