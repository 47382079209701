.legal_page {
    padding-top: 110px;
}

.legal_content {
    padding: 50px 10%;
}

.legal_content h1 {
    font-size: 22px;
}

.legal_content h2 {
    font-size: 20px;
    font-weight: 600;
}

.legal_content p {
    display: block;
    font-size: 16px;
    font-weight: 400;
    text-align: justify;
    margin: 10px 0;
}

.legal_content ul {
    margin-left: 20px;
}