.purchase_app {
    width: 100%;
    min-height: calc(100vh - 100px);
    background-color: #f5efef;
    padding: 30px 10%;
    display: grid;
    grid-template-columns: 65% 35%;
    grid-template-rows: min-content auto;
}

.purchase_app .title {
    display: flex;
    flex-direction: column;
    grid-column: 1 / span 2;
    padding-bottom: 20px;
}

.purchase_app .title h2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 600;
}

@media (max-width: 996px) {
    .purchase_app {
        padding: 30px 10%;
        grid-template-columns: 1fr;
    }

    .purchase_app .title {
        grid-column: 1 / span 1;
    }
    
    .purchase_app .title h2 {
        display: flex;
        font-size: 14px;
        flex-wrap: wrap;
    }
}

.purchase_app .col1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.purchase_app .col1 .products_container {
    width: 95%;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.12);
    margin-bottom: 20px;
}

@media (max-width: 996px) {
    .purchase_app .col1 .products_container {
        width: 100%;
    }
}

.purchase_app .col1 .products_container .header {
    display: flex;
    align-items: center;
    padding: 7px 20px;
    border-bottom: 1px solid #d8d8d8;
}

.purchase_app .col1 .products_container .header h2 {
    font-weight: 500;
    font-size: 16px;
}

.purchase_app .col1 .products_container .body {
    min-height: 30px;
    max-height: 300px;
    overflow-y: auto;
}

.purchase_app .col1 .delivery_container {
    width: 95%;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.12);
}

@media (max-width: 996px) {
    .purchase_app .col1 .delivery_container {
        width: 100%;
    }
}

.purchase_app .col1 .delivery_container .header {
    display: flex;
    align-items: center;
    padding: 7px 20px;
    border-bottom: 1px solid #d8d8d8;
}

.purchase_app .col1 .delivery_container .header h2 {
    font-weight: 500;
    font-size: 16px;
}

.purchase_app .col1 .delivery_container .body {
    padding: 20px;
}

.purchase_app .col1 .delivery_container .body h2 {
    font-size: 16px;
    font-weight: 400;
    color: #383838;
}

.purchase_app .col1 .delivery_container .body p {
    font-size: 14px;
    color: #666;
    margin-top: 10px;
}

.purchase_app .col2 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.purchase_app .col2 .order_pricing_summary {
    width: 95%;
}

@media (max-width: 996px) {
    .purchase_app .col2 {
        margin-top: 20px;
    }
    
    .purchase_app .col2 .order_pricing_summary {
        width: 100%;
    }
}

.purchase_app .col2 .order_pricing_summary .order_summary {
    width: 100%;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.12);
}

.purchase_app .col2 .order_pricing_summary .order_summary .header {
    display: flex;
    align-items: center;
    padding: 7px 20px;
    border-bottom: 1px solid #d8d8d8;
}

.purchase_app .col2 .order_pricing_summary .order_summary .header h2 {
    font-weight: 500;
    font-size: 16px;
}

.purchase_app .col2 .order_pricing_summary .order_summary .body {
    padding: 20px;
}

.purchase_app .col2 .order_pricing_summary .order_summary .body .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.purchase_app .col2 .order_pricing_summary .order_summary .body .item > div {
    font-size: 14px;
    font-weight: 400;
    color: #666;
}

.purchase_app .col2 .order_pricing_summary .order_summary .body .item.total > div {
    font-weight: 500;
    color: #000;
    font-size: 16px;
}

.purchase_app .col2 .order_pricing_summary .order_state {
    width: 100%;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.12);
    margin-bottom: 20px;
}

.purchase_app .col2 .order_pricing_summary .order_state .header {
    display: flex;
    align-items: center;
    padding: 7px 20px;
    border-bottom: 1px solid #d8d8d8;
}

.purchase_app .col2 .order_pricing_summary .order_state .header h2 {
    font-weight: 500;
    font-size: 16px;
}

.purchase_app .col2 .order_pricing_summary .order_state .body {
    padding: 10px 20px;
}

.purchase_app .col2 .order_pricing_summary .order_state .body h2 {
    font-size: 14px;
    font-weight: 500;
}