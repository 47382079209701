* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Poppins';
}

a {
	text-decoration: none;
}

@font-face {
	font-family: 'RaceSport';
	src: local('RaceSport'), url(./assets/fonts/Race-Sport.woff) format('woff');
}

@media (max-width: 996px) { 
	body {
		max-width: 100vw;
		overflow-x: hidden;
	}
}

.fancy_input_group {
	display: flex;
	gap: 10px;
}

.fancy_input_group .fancy_input {
	width: 100%;
}

.fancy_input {
	display: flex;
	flex-direction: column;
}

.fancy_input label {
	font-size: 14px;
	color: rgba(0, 0, 0, .9);
	margin-bottom: 3px;
}

.fancy_input span {
	font-size: 14px;
	color: #dc3545;
}

.fancy_input input {
	width: 100%;
    border: 1px solid #d8d8d8;
    font-size: 16px;
    padding: 4px 15px;
    border-radius: 5px;
    outline: none;
}

.fancy_input select {
	width: 100%;
    border: 1px solid #d8d8d8;
    font-size: 16px;
    padding: 4px 15px;
    border-radius: 5px;
    outline: none;
}

.fancy_input textarea {
	width: 100%;
	height: 100px;
    border: 1px solid #d8d8d8;
    font-size: 16px;
    padding: 10px 15px;
    border-radius: 5px;
    outline: none;
}

.error_msg {
	width: 100%;
	background-color: #dc3545;
	padding: 10px;
	border-radius: 5px;
	color: #fff;
	font-size: 16px;
	font-weight: 500;
}

button:disabled {
	cursor: not-allowed !important;
}

.primary_btn {
	background-color: #090e3c;
	border: none;
	color: #fff;
	font-size: 16px;
	font-weight: 400;
	padding: 5px 25px;
	border-radius: 5px;
	cursor: pointer;
}

.secundary_btn {
	border: none;
	background-color: #fff;
	color: #090e3c;
	font-size: 16px;
	font-weight: 500;
	padding: 5px 25px;
	border-radius: 5px;
	box-shadow: 0 1px 2px 1px rgba(0,0,0,.12);
	cursor: pointer;
}

@media (max-width: 996px) {	
	.primary_btn {
		font-size: 14px;
		padding: 7px 25px;
	}

	.secundary_btn {
		font-size: 14px;
		padding: 7px 25px;
	}
}