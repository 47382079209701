.lights_page_main_section {
    position: relative;
    width: 100%;
    padding: 100px 2.5%;
}

.lights_page_main_section::before {
    content: "";
    position: absolute;
    right: 30%;
    top: 0;
    width: 13px;
    height: 100%;
    background-color: #fac517;
    z-index: -1;
}

.lights_page_main_section::after {
    content: "";
    position: absolute;
    right: 30%;
    top: 0;
    width: 13px;
    height: 100%;
    background-color: #fac517;
    transform: translateX(30px);
    z-index: -1;
}

.lights_main_container {
    padding-top: 100px;
    display: grid;
    grid-template-columns: 40% 60%;
}

.lights_main_container .media {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding-top: 50px;
}

.lights_main_container .media .image_container {
    position: relative;
    width: 70%;
}

.lights_main_container .media .image_container .img {
    position: relative;
    width: 100%;
    height: 350px;
    overflow: hidden;
    z-index: 9;
}

.lights_main_container .media .image_container .img img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: .25s;
}

.lights_main_container .media .image_container .square {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #090e3c;
    border: 2px solid transparent;
    transform: translate(-30px, 30px);
    transition: .2s;
}

.lights_main_container .media .image_container:hover .square {
    transform: translate(-35px, 35px);
    background-color: transparent;
    border: 2px solid #090e3c;
}

.lights_main_container .media .image_container:hover .img img {
    transform: scale(1.1);
}

.lights_main_container .content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.lights_main_container .content h1 {
    font-size: 70px;
    line-height: 1;
    
    color: #090e3c;
}

.lights_main_container .content h1 span {
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #090e3c;
  padding-left: 50px;
}

.lights_main_container .content h2 {
    margin-top: 30px;
    font-size: 24px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.lights_main_container .content .arrow {
    align-self: center;
    transform: rotate(0deg);
    cursor: pointer;
    margin-top: 35px;
}

.lights_main_container .content .arrow span {
    display: block;
    width: 3vw;
    height: 3vw;
    border-bottom: 3px solid #090e3c;
    border-right: 3px solid #090e3c;
    transform: rotate(45deg);
    margin: -20px;
    animation: animateLightsArrow 2s infinite;
}

.lights_main_container .content .arrow span:nth-child(2) {
    animation-delay: -0.2s;
}

.lights_main_container .content .arrow span:nth-child(3) {
    animation-delay: -0.4s;
}

@keyframes animateLightsArrow {
    0% {
        opacity: 0;
        transform: rotate(45deg) translate(-20px, -20px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(45deg) translate(20px, 20px);
    }
}

@media (max-width: 996px) {
    .lights_page_main_section {
        padding: 50px 5%;
        padding-bottom: 100px;
    }
    
    .lights_page_main_section::before {
        display:  none;
    }
    
    .lights_page_main_section::after {
        display: none;
    }
    
    .lights_main_container {
        padding-top: 0;
        grid-template-columns: 1fr;
    }
    
    .lights_main_container .media {
        align-items: center;
        justify-content: center;
        padding-top: 70px;
    }
    
    .lights_main_container .media .image_container {
        position: relative;
        width: 80%;
    }
    
    .lights_main_container .media .image_container .img {
        height: 180px;
    }
    
    .lights_main_container .content {
        justify-content: center;
    }
    
    .lights_main_container .content h1 {
        font-size: 40px;
        text-align: center;
    }
    
    .lights_main_container .content h1 span {
      padding-left: 0;
    }
    
    .lights_main_container .content h2 {
        margin-top: 30px;
        font-size: 16px;
    }
    
    .lights_main_container .content .arrow {
        margin-top: 20px;
    }
    
    .lights_main_container .content .arrow span {
        width: 7vw;
        height: 7vw;
        margin: -10px;
    }
}

.led_advantages_section {
    position: relative;
    width: 100%;
    padding: 0 10%;
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: #090e3c;
}

.led_advantages_section .jeep_car {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    transform: translateY(-30%);
}

.led_advantages_section .container {
    width: 100%;
    display: grid;
    grid-template-columns: 40% 60%;
}

.led_advantages_section .container .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.led_advantages_section .container .title h2 {
    font-size: 70px;
    color: #fff;
}

.led_advantages_section .container .title .image_holder {
    position: relative;
    width: 100%;
    height: 300px;
    border-radius: 15px;
    overflow: hidden;
    background-color: #cc0000;
}

.led_advantages_section .container .title .image_holder img {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left bottom;
}

.led_advantages_section .container .content {
    display: flex;
    flex-direction: column;
    padding-left: 10%;
}

.led_advantages_section .container .content .item {
    width: 100%;
    margin-bottom: 25px;
}

.led_advantages_section .container .content .item .item_title {
    display: flex;
    align-items: center;
}

.led_advantages_section .container .content .item .item_title svg {
    height: 30px;
    stroke: #28a745;
    margin-right: 5px;
}

.led_advantages_section .container .content .item .item_title svg path {
    stroke: #28a745;
}

.led_advantages_section .container .content .item .item_title h2 {
    font-size: 22px;
    color: #fff;
    font-weight: 600;
}

.led_advantages_section .container .content .item p {
    color: #fff;
    font-size: 16px;
    text-align: justify;
}

@media (max-width: 996px) {
    .led_advantages_section {
        padding: 0 5%;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    
    .led_advantages_section .jeep_car {
        width: 80%;
    }
    
    .led_advantages_section .container {
        grid-template-columns: 1fr;
    }
    
    .led_advantages_section .container .title h2 {
        font-size: 40px;
        padding-bottom: 20px;
    }
    
    .led_advantages_section .container .title {
        padding-bottom: 25px;
    }

    .led_advantages_section .container .content {
        padding-left: 0;
    }
    
    .led_advantages_section .container .content .item {
        margin-bottom: 20px;
    }
    
    .led_advantages_section .container .content .item .item_title svg {
        height: 25px;
    }

    .led_advantages_section .container .content .item .item_title h2 {
        font-size: 18px;
    }
    
    .led_advantages_section .container .content .item p {
        font-size: 14px;
    }    
}

.led_gallery_section {
    width: 100%;
    padding: 50px 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.led_gallery_section .item {
    position: relative;
    width: 31%;
    height: 240px;
    background-color: #090e3c;
    border-radius: 15px;
    overflow: hidden;
}

.led_gallery_section .item img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: .25s;
}

.led_gallery_section .item:hover img {
    transform: scale(1.2);
}

.led_gallery_section .slide_item {
    position: relative !important;
    height: 300px;
    background-color: #090e3c;
    border-radius: 15px;
    overflow: hidden;
}

.led_gallery_section .slide_item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

@media (max-width: 996px) {
    .led_gallery_section {
        width: 100%;
        padding: 20px 5%;
    }
    
    .led_gallery_section .item {
        width: 100%;
        height: 240px;
        margin-bottom: 20px;
    }
}

.led_vs_section {
    width: 100%;
    height: 70vh;
    background-color: #090e3c;
    display: grid;
    grid-template-columns: 50% 50%;
}

.led_vs_section > div {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    padding-top: 20px;
}

.led_vs_section > div img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: .25s;
}

.led_vs_section > div h2 {
    position: relative;
    color: #fff;
    font-size: 40px;
    z-index: 9;
}

@media (max-width: 996px) {
    .led_vs_section {
        height: 70vh;
        grid-template-columns: 1fr;
    }
    
    .led_vs_section > div {
        padding-top: 15px;
    }

    .led_vs_section > div h2 {
        font-size: 30px;
    }
}

.led_sistecredito_section {
    width: 100%;
    padding: 100px 10%;
    background-color: #090e3c;
    display: grid;
    grid-template-columns: 45% 55%;
}

.led_sistecredito_section .media {
    display: flex;
    justify-content: center;
    align-items: center;
}

.led_sistecredito_section .media .image_container {
    position: relative;
    width: 80%;
    height: 300px;
    background-color: #cc0000;
    border-radius: 15px;
}

.led_sistecredito_section .media .image_container .car {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left bottom;
    border-radius: 15px;
    z-index: 1;
}

.led_sistecredito_section .media .image_container .siste {
    position: absolute;
    height: 100%;
    right: 0;
    transform: translateX(30%) translateY(50%);
    z-index: 9;
}

.led_sistecredito_section .media > img {
    width: 100%;
}

.led_sistecredito_section .content {
    display: flex;
    align-items: center;
}

.led_sistecredito_section .content h2 {
    font-size: 55px;
    color: #fff;
    line-height: 1;
}

.led_sistecredito_section .content h2 .sp1 {
    padding-left: 80px;
}

.led_sistecredito_section .content h2 .sp2 {
    padding-left: 20px;
}

@media (max-width: 996px) {
    .led_sistecredito_section {
        padding: 40px 5%;
        padding-bottom: 170px;
        grid-template-columns: 1fr;
    }
    
    .led_sistecredito_section .media {
        padding-top: 30px;
    }
    
    .led_sistecredito_section .media .image_container {
        width: 90%;
        height: 250px;
    }

    .led_sistecredito_section .media .image_container .siste {
        position: absolute;
        height: 100%;
        right: 50%;
        transform: translateX(50%) translateY(60%);
        z-index: 9;
    }
    
    .led_sistecredito_section .content {
        justify-content: center;
    }
    
    .led_sistecredito_section .content h2 {
        font-size: 30px;
    }
    
    .led_sistecredito_section .content h2 .sp1 {
        padding-left: 80px;
    }
    
    .led_sistecredito_section .content h2 .sp2 {
        padding-left: 20px;
    }
}

.led_visit_us_section {
    width: 100%;
    padding: 50px 10%;
    display: grid;
    grid-template-columns: 55% 45%;
}

.led_visit_us_section .media {
    display: flex;
    justify-content: center;
    align-items: center;
}

.led_visit_us_section .media img {
    width: 100%;
    border-radius: 15px;
}

.led_visit_us_section .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.led_visit_us_section .content h2 {
    color: #090e3c;
    font-size: 50px;
}

.led_visit_us_section .content p {
    text-align: center;
    font-size: 30px;
    line-height: 1.1;
    font-weight: 500;
    margin-top: 20px;
}

@media (max-width: 996px) {
    .led_visit_us_section {
        padding: 30px 5%;
        grid-template-columns: 1fr;
    }

    .led_visit_us_section .media {
        grid-row: 2;
        padding-top: 20px;
    }

    .led_visit_us_section .content h2 {
        font-size: 40px;
    }

    .led_visit_us_section .content p {
        font-size: 22px;
        margin-top: 5px;
    }
}