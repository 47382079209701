.addresses_modal_container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, .6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 501;
}

.addresses_modal_container .form_container {
    width: 50%;
    padding: 25px 30px;
    background-color: #fff;
    border-radius: 5px;
}

.addresses_modal_container .form_container > .title {
    font-size: 20px;
    font-weight: 600;
    color: rgba(0, 0, 0, .8);
    margin-bottom: 20px;
}

@media (max-width: 996px) {
    .addresses_modal_container .form_container {
        width: 90%;
        padding: 22px 20px;
    }

    .addresses_modal_container .form_container > .title {
        font-size: 18px;
        line-height: 1.2;
        text-align: center;
    }
}

.addresses_modal_container .form_container .addresses_container {
    display: grid;
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 2px 1px rgba(0,0,0,.12);
    grid-template-rows: auto 40px;
    margin-bottom: 20px;
}

.addresses_modal_container .form_container .addresses_container .body {
    min-height: 30px;
    max-height: 300px;
    overflow-y: auto;
}

@media (max-width: 996px) {
    .addresses_modal_container .form_container .addresses_container .body {
        min-height: 30px;
        max-height: 275px;
    }    
}

.addresses_modal_container .form_container .addresses_container .footer {
    border-top: 1px solid #d8d8d8;
    display: flex;
    align-items: center;
    padding: 0 15px;
}

.addresses_modal_container .form_container .addresses_container .footer a {
    font-size: 14px;
    font-weight: 400;
}

.addresses_modal_container .form_container .actions {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.addresses_modal_container .form_container .actions button {
    margin-left: 10px;
}

@media (max-width: 996px) {
    .addresses_modal_container .form_container .actions button {
        margin-left: 5px;
        padding: 5px 10px;
    }    
}