.product_search_item {
    display: flex;
    align-items: center;
    padding: 5px 20px;
    cursor: pointer;
}

.product_search_item:hover {
    background-color: #f4f0f0;
}

.product_search_item .pic {
    position: relative;
    width: 35px;
    height: 35px;
    border-radius: 5px;
    border: 1px solid #d8d8d8;
    overflow: hidden;
}

.product_search_item .pic img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.product_search_item .name {
    margin-left: 10px;
    flex: 1;
}

.product_search_item .name h2 {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}