.product_added_app {
    width: 100%;
    min-height: calc(100vh - 100px);
    background-color: #f5efef;
}

.product_added_app .container {
    display: flex;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0px 2px 0 rgba(0,0,0,.12);
    padding: 15px 10%;
    align-items: center;
}

.product_added_app .container .pic {
    position: relative;
    width: 90px;
    height: 90px;
    border-radius: 5px;
    border: 1px solid #d8d8d8;
    margin-right: 25px;
    overflow: hidden;
}

.product_added_app .container .pic img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.product_added_app .container .info {
    min-width: 45%;
    max-width: 45%;
    display: flex;
    flex-direction: column;
}

@media (max-width: 996px) {
    .product_added_app {
        width: 100%;
        min-height: calc(100vh - 60px);
        background-color: #f5efef;
    }

    .product_added_app .container {
        display: flex;
        flex-wrap: wrap;
        padding: 15px 5%;
        align-items: center;
    }

    .product_added_app .container .info {
        min-width: 65%;
        max-width: 65%;
        display: flex;
        flex-direction: column;
    }    
}

.product_added_app .container .info h2 {
    font-size: 18px;
    font-weight: 500;
    color: #090e3c;
}

.product_added_app .container .info p {
    max-width: 100%;
    font-size: 14px;
    margin-top: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product_added_app .container .actions {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
}

.product_added_app .container .actions button, .product_added_app .container .actions a {
    margin-left: 10px;
}

@media (max-width: 996px) {
    .product_added_app .container .actions {
        display: flex;
        justify-content: flex-end;
        margin-left: auto;
        margin-right: auto;
        margin-top: 15px;
    }
}