.profile_side_menu {
    min-width: 300px;
    max-width: 300px;
    padding-top: 20px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-right: 1px solid #d8d8d8;
    /* box-shadow: 0 1px 2px 0 rgba(0,0,0,.12); */
}

@media (max-width: 996px) {
    .profile_side_menu {
        min-width: 100%;
        max-width: 100%;
        padding-bottom: 20px;
    }    
}

.profile_side_menu a {
    position: relative;
    display: flex;
    align-items: center;
    padding: 15px 15px;
    font-size: 16px;
    color: #666;
}

.profile_side_menu a.active::before, .profile_side_menu a:hover::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background-color: #090e3c;
}

.profile_side_menu a svg {
    height: 25px;
    margin-right: 10px;
}

.profile_side_menu a svg path {
    fill: #666;
}
