.qty_input_container {
    position: relative;
    display: flex;
    height: 35px;
    align-items: stretch;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    box-shadow: 0 0 1px 0 rgba(0,0,0,.12);
    overflow: hidden;
}

.qty_input_container button {
    width: 35px;
    border: none;
    background-color: #fff;
    cursor: pointer;
}

.qty_input_container input {
    border: none;
    width: 50px;
    text-align: center;
    outline: none;
}

.qty_input_container .loader {
    position: absolute;
    width: 100%;
    bottom: -2px;
    left: 0;
}