.purchase_item {
    width: 100%;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.12);
}

.purchase_item .header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #d8d8d8;
}

.purchase_item .header h2 {
    font-size: 16px;
    font-weight: 500;
}

.purchase_item .body_purchases {
    min-height: 20px;
    max-height: 120px;
    overflow-y: auto;
}

.purchase_item .footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-top: 1px solid #d8d8d8;
}

.purchase_item .footer h2 {
    font-size: 16px;
    font-weight: 500;
}

.purchase_item .footer .status {
    font-size: 16px;
}