.addresses_modal_address_item {
    display: flex;
    width: 100%;
    padding: 15px 15px;
    cursor: pointer;
    align-items: center;
}

.addresses_modal_address_item:hover {
    background-color: #f5f0f0;
}

.addresses_modal_address_item .select {
    margin-right: 15px;
}

.addresses_modal_address_item .info h2 {
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, .9);
    line-height: 1;
    user-select: none;
}

.addresses_modal_address_item .info p {
    font-size: 14px;
    color: #494949;
    margin-top: 3px;
    user-select: none;
}

.addresses_modal_address_item .delete {
    margin-left: auto;
    font-size: 14px;
}