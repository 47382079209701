.store_header {
    width: 100%;
    height: 100px;
    background-color: #090e3c;
    padding: 10px 10%;
    display: flex;
    flex-direction: column;
}

.store_header .row1 {
    display: flex;
    align-items: center;
}

.store_header .row1 .logo {
    height: 40px;
    margin-right: 30px;
}

.store_header .row1 .logo img {
    height: 100%;
}

.store_header .row2 {
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: flex-end;
}

.store_header .row2 .col1 {
    display: flex;
}

.store_header .row2 .col1 > a {
    color: #fff;
    font-size: 14px;
    padding: 5px 15px;
    height: 40px;
    display: flex;
    align-items: center;
}

.store_header .row2 .col1 .address_link {
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.store_header .row2 .col2 {
    display: flex;
}

.store_header .row2 .col2 > a {
    color: #fff;
    font-size: 16px;
    padding: 5px 15px;
    display: flex;
    align-items: center;
}

@media (max-width: 996px) { 
    .store_header {
        display: none;
    }
}

.store_header .user_dropdown_container {
    position: relative;
    z-index: 101;
}

.store_header .user_dropdown_container .info {
    height: 40px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    color:  #fff;
    font-size: 16px;
    cursor: pointer;
    user-select: none;
}

.store_header .user_dropdown_container .info svg {
    height: 40px;
    margin-right: 10px;
}

.store_header .user_dropdown_container .info svg path {
    fill: #fff;
}

.store_header .user_dropdown_container .dropdown {
    position: absolute;
    width: 300px;
    right: 0;
    bottom: 0;
    transform: translateY(100%);
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 2px 1px rgba(0,0,0,.12);
    visibility: hidden;
    opacity: 0;
    transition: .25s;
}

.store_header .user_dropdown_container:hover .dropdown {
    visibility: visible;
    opacity: 1;
}

.store_header .user_dropdown_container .dropdown .header {
    width: 100%;
    display: flex;
    padding: 10px 15px;
    align-items: center;
    border-bottom: 1px solid #d8d8d8;
    cursor: pointer;
}

.store_header .user_dropdown_container .dropdown .header .icon {
    height: 50px;
}

.store_header .user_dropdown_container .dropdown .header .icon svg {
    height: 100%;
}

.store_header .user_dropdown_container .dropdown .header .name {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.store_header .user_dropdown_container .dropdown .header .name h2 {
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    user-select: none;
}

.store_header .user_dropdown_container .dropdown .header .name a {
    width: 100%;
    padding: 0;
    font-size: 14px;
    color: #3d3d3d;
    font-weight: 500;
}

.store_header .user_dropdown_container .dropdown .body {
    display: flex;
    flex-direction: column;
}

.store_header .user_dropdown_container .dropdown .body a {
    width: 100%;
    padding: 10px 15px;
    color: #333;
    font-size: 14px;
    font-weight: 500;
}

.store_header .user_dropdown_container .dropdown .body a:hover {
    background-color: #e6e6e6;
}

.store_header .user_dropdown_container .dropdown .body .divider {
    width: 100%;
    height: 1px;
    margin: 5px 0;
    background-color: #d8d8d8;
}