.addresses_app {
    width: 100%;
    min-height: calc(100vh - 100px);
    background-color: #f5efef;
}

.addresses_app .body {
    display: flex;
    width: 100%;
    min-height: calc(100vh - 100px);
}

.addresses_app .address_app_content {
    flex: 1;
    padding: 50px 10%;
}

@media (max-width: 996px) {
    .addresses_app .body {
        flex-direction: column;
    }

    .addresses_app .address_app_content {
        padding: 50px 5%;
    }
}

.addresses_app .address_app_content .address_container {
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 2px 1px rgba(0,0,0,.12);
    margin-bottom: 15px;
}

.addresses_app .address_app_content .address_container .header {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #d8d8d8;
}

.addresses_app .address_app_content .address_container .header h2 {
    font-size: 18px;
    font-weight: 500;
}

.addresses_app .address_app_content .address_container .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-top: 1px solid #d8d8d8;
}