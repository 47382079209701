.shopping_cart_item {
    display: flex;
    width: 100%;
    padding: 15px 20px;
    border-bottom: 1px solid #d8d8d8;
    align-items: center;
}

@media (max-width: 996px) {
    .shopping_cart_item {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    
    .shopping_cart_item .qty_input_container {
        margin-top: 10px;
    }
}

.shopping_cart_item .pic {
    position: relative;
    min-width: 65px;
    max-width: 65px;
    min-height: 65px;
    max-height: 65px;
    margin-right: 20px;
    border-radius: 5px;
    border: 1px solid #d8d8d8;
}

.shopping_cart_item .pic img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.shopping_cart_item .info {
    min-width: 50%;
    max-width: 50%;
    display: flex;
    flex-direction: column;
}

.shopping_cart_item .info h2 {
    max-width: 100%;
    font-size: 18px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #242424;
}

.shopping_cart_item .info a {
    width: fit-content;
    display: flex;
    font-size: 14px;
    padding: 5px 0;
}

.shopping_cart_item .price {
    display: flex;
    margin-left: auto;
    flex-direction: column;
    align-items: flex-end;
}

.shopping_cart_item .price .discount {
    display: flex;
    align-items: center;
}

.shopping_cart_item .price .discount .percentaje_discount {
    font-size: 12px;
    color: #00a650;
}

.shopping_cart_item .price .discount .old_price {
    position: relative;
    font-size: 12px;
    margin-left: 3px;
    color: #666;
}

.shopping_cart_item .price .discount .old_price::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-color: #353535;
}

.shopping_cart_item .price h2 {
    font-size: 18px;
    font-weight: 500;
    line-height: 1;
}