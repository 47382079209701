.whatsapp {
    position:fixed;
    width:60px;
    height:60px;
    bottom:40px;
    right:40px;
    background-color:#25d366;
    color:#FFF;
    border-radius:50px;
    text-align:center;
    font-size:30px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index:100;
}

@media (max-width: 996px) {
    .whatsapp {
        bottom:20px;
        right:10px;
    }
}