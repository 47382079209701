.shopping_cart_app {
    display: flex;
    width: 100%;
    min-height: calc(100vh - 100px);
    padding: 40px 10%;
    background-color: #f5efef;
    justify-content: space-between;
    align-items: flex-start;
}

.shopping_cart_app .shopping_cart_container {
    min-width: 65%;
    max-width: 65%;
    background-color: #fff;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.12);
    border-radius: 5px;
}

@media (max-width: 996px) {
    .shopping_cart_app {
        flex-direction: column-reverse;
        min-height: calc(100vh - 60px);
        padding: 30px 5%;
    }
    
    .shopping_cart_app .shopping_cart_container {
        min-width: 100%;
        max-width: 100%;
    }
}

.shopping_cart_app .shopping_cart_container .header {
    padding: 7px 20px;
    border-bottom: 1px solid #d8d8d8;
}

.shopping_cart_app .shopping_cart_container .header h2 {
    font-size: 18px;
    font-weight: 500;
}

.shopping_cart_app .shopping_cart_container .body {
    min-height: 30px;
}

.shopping_cart_app .shopping_cart_container .footer {
    padding: 7px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.shopping_cart_app .shopping_cart_container .footer h2 {
    font-size: 18px;
    font-weight: 600;
    color: #3f3f3f;
}

.shopping_cart_app .shopping_cart_container .footer h2 span {
    font-weight: 500;
}

@media (max-width: 996px) {
    .shopping_cart_app .shopping_cart_container .footer {
        padding: 10px 20px;
    }

    .shopping_cart_app .shopping_cart_container .footer button {
        padding: 5px 15px;
    }

    .shopping_cart_app .shopping_cart_container .footer h2 {
        font-size: 14px;
    }
}