.purchase_product_item {
    display: flex;
    width: 100%;
    height: 80px;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #d8d8d8;
}

.purchase_product_item .pic {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 5px;
    border: 1px solid #d8d8d8;
    overflow: hidden;
}

.purchase_product_item .pic img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.purchase_product_item .info {
    min-width: 50%;
    max-width: 50%;
    padding-left: 10px;
}

.purchase_product_item .info h2 {
    max-width: 100%;
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.purchase_product_item .info p {
    font-size: 12px;
    color: #666;
}

.purchase_product_item .total {
    margin-left: auto;
    font-size: 14px;
}
