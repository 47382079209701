.header_search {
    position: relative;
    z-index: 101;
}

.header_search .search_input {
    width: 500px;
    height: 40px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.2);
    overflow: hidden;
    display: flex;
}

@media (max-width: 996px) {
    .header_search {
        flex: 1;
    }

    .header_search .search_input {
        width: 100%;
    }
} 


.header_search .search_input input {
    width: 100%;
    height: 100%;
    border: none;
    padding: 0 15px;
    outline: none;
}

.header_search .search_input button {
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    border: none;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    color: #090e3c;
    font-size: 12px;
    cursor: pointer;
}

.header_search .search_results {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    transform: translateY(calc(100% + 10px));
    background-color: #fff;
    box-shadow: 0 1px 2px 1px rgba(0,0,0,.12);
    border-radius: 5px;
}

.header_search .search_results .header {
    padding: 10px 20px;
    border-bottom: 1px solid #d8d8d8;
}

.header_search .search_results .header h2 {
    font-size: 16px;
    font-weight: 500;
}

.header_search .search_results .body {
    max-height: 250px;
    overflow-y: auto;
}

.header_search .search_results .body .noresults {
    padding: 10px 20px;
    font-size: 14px;
    color: #666;
    text-align: center;
}

.header_search .search_results .footer {
    padding: 7px 20px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #d8d8d8;
}
