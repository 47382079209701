.webpage_header {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: 95%;
    height: 80px;
    background-color: #090e3c;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    z-index: 99;
}

.webpage_header .main_links {
    height: 100%;
    display: flex;
    align-items: stretch;
}

.webpage_header .main_links a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    color: #fff;
    font-size: 18px;
}

.webpage_header .logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    display: flex;
    align-items: center;
}

.webpage_header .logo img {
    height: 60%;
}

.webpage_header .second_links {
    height: 100%;
    display: flex;
    align-items: stretch;
}


.webpage_header .second_links > a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    color: #fff;
    font-size: 18px;
}

.whatsapp_btn_container {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.whatsapp_btn {
    background-color: #00d65d;
    color: #fff;
    font-weight: 500;
    padding: 8px 25px;
    border-radius: 75px;
    font-size: 18px;
    display: flex;
    align-items: center;
    line-height: 1;
}

.whatsapp_btn i {
    margin-right: 10px;
    font-size: 22px;
}

.webpage_responsive_header_toggle {
    display: none;
    position: fixed;
    right: 10px;
    top: 10px;
    width: 40px;
    height: 40px;
    background-color: #090e3c;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 18px;
    z-index: 199;
}

.header_backdrop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, .5);
    backdrop-filter: blur(2px);
    z-index: 181;
    opacity: 0;
    visibility: hidden;
    transition: .2s;
}

.header_backdrop.active {
    visibility: visible;
    opacity: 1;
}

.webpage_header_responsive {
    position: fixed;
    right: 0;
    top: 0;
    padding: 30px 5%;
    padding-bottom: 70px;
    width: 60vw;
    height: 100vh;
    background-color: #090e3c;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 199;
    transform: translateX(100%);
    transition: .2s;
}

.webpage_header_responsive .logo {
    width: 80%;
    margin-bottom: 30px;
}

.webpage_header_responsive .logo img {
    width: 100%;
}

.webpage_header_responsive .close {
    display: none;
    position: absolute;
    top: 10px;
    left: -10px;
    width: 40px;
    height: 40px;
    background-color: #090e3c;
    border: none;
    border-radius: 15px;
    outline: none;
    transform: translateX(-100%);
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 18px;
}

.webpage_header_responsive.active {
    transform: translateX(0);
}

.webpage_header_responsive .close #nav-icon1 {
    width: 20px;
    height: 18px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}
  
.webpage_header_responsive .close #nav-icon1 span{
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #fff;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}
  
.webpage_header_responsive .close #nav-icon1 span:nth-child(1) {
    top: 0px;
}
  
.webpage_header_responsive .close #nav-icon1 span:nth-child(2) {
    top: 9px;
}
  
.webpage_header_responsive .close #nav-icon1 span:nth-child(3) {
    top: 18px;
}
  
.webpage_header_responsive .close #nav-icon1.open span:nth-child(1) {
    top: 9px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}
  
.webpage_header_responsive .close #nav-icon1.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
}
  
.webpage_header_responsive .close #nav-icon1.open span:nth-child(3) {
    top: 9px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

.webpage_header_responsive .item {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 16px;
}

.webpage_header_responsive .social_media {
    display: flex;
    justify-content: center;
    padding-left: 10px;
    margin-top: auto;
    width: 100%;
}

.webpage_header_responsive .social_media a {
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #090e3c;
    font-size: 18px;
}

@media (max-width: 996px) {
    .webpage_header {
        display: none;
    }

    .webpage_responsive_header_toggle {
        display: flex;
    }

    .webpage_header_responsive .close {
        display: flex;
    }
}