.purchase_delivery_app {
    display: flex;
    width: 100%;
    min-height: calc(100vh - 100px);
    padding: 30px 10%;
    background-color: #f5efef;
    justify-content: space-between;
    align-items: flex-start;
}

.purchase_delivery_app .delivery_method_container {
    min-width: 65%;
    max-width: 65%;
}

@media (max-width: 996px) {
    .purchase_delivery_app {
        flex-direction: column;
        min-height: calc(100vh - 60px);
        padding: 20px 5%;
    }

    .purchase_delivery_app .delivery_method_container {
        min-width: 100%;
        max-width: 100%;
    }
}

.purchase_delivery_app .delivery_method_container > .title {
    height: 70px;
    font-size: 22px;
    font-weight: 600;
    color: #2b2b2b;
    user-select: none;
}

@media (max-width: 996px) {
    .purchase_delivery_app .delivery_method_container > .title {
        font-size: 18px;
        height: 45px;
    }
}

.purchase_delivery_app .delivery_method_container > .error_msg {
    margin-bottom: 20px;
}

.purchase_delivery_app .delivery_method_container .method_container {
    width: 100%;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.12);
}

.purchase_delivery_app .delivery_method_container .method_container .header {
    display: flex;
    align-items: center;
    padding: 10px 25px;
    border-bottom: 1px solid #d8d8d8;
}

.purchase_delivery_app .delivery_method_container .method_container .header input {
    margin-right: 10px;
    margin-top: 3px;
}

.purchase_delivery_app .delivery_method_container .method_container .header h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 1;
    color: #2b2b2b;
    user-select: none;
}

@media (max-width: 996px) {
    .purchase_delivery_app .delivery_method_container .method_container .header {
        padding: 15px 25px;
    }

    .purchase_delivery_app .delivery_method_container .method_container .header h2 {
        font-size: 18px;
    }
}

.purchase_delivery_app .delivery_method_container .method_container .header .price {
    font-size: 18px;
    margin-left: auto;
    color: #090e3c;
}

.purchase_delivery_app .delivery_method_container .method_container .body {
    padding: 20px 49px;
}

.purchase_delivery_app .delivery_method_container .method_container .body p {
    font-size: 16px;
    color: #666;
}

.purchase_delivery_app .delivery_method_container .method_container .footer {
    padding: 10px 25px;
    border-top: 1px solid #d8d8d8;
}

@media (max-width: 996px) {
    .purchase_delivery_app .delivery_method_container .method_container .footer {
        padding: 15px 25px;
    }
}

.purchase_delivery_app .purchase_summary_container {
    min-width: 30%;
    max-width: 30%;
    padding-top: 70px;
}

@media (max-width: 996px) {
    .purchase_delivery_app .purchase_summary_container {
        min-width: 100%;
        max-width: 100%;
        padding-top: 20px;
    }
}

.purchase_delivery_app .purchase_summary_container .summary_container {
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.12);
}

.purchase_delivery_app .purchase_summary_container .summary_container .header {
    padding: 10px 20px;
    border-bottom: 1px solid #d8d8d8;
}

.purchase_delivery_app .purchase_summary_container .summary_container .header h2 {
    font-size: 18px;
    font-weight: 500;
    color: #2b2b2b;
}

.purchase_delivery_app .purchase_summary_container .summary_container .body {
    padding: 20px;
}

.purchase_delivery_app .purchase_summary_container .summary_container .body .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 0;
}

.purchase_delivery_app .purchase_summary_container .summary_container .body .item h2 {
    font-size: 14px;
    font-weight: 400;
}

.purchase_delivery_app .purchase_summary_container .summary_container .body .item span {
    font-size: 14px;
}

.purchase_delivery_app .purchase_summary_container .summary_container .body .primary_btn {
    width: 100%;
    padding: 10px 20px;
    margin-top: 20px;
}