.payment_verification_app {
    display: flex;
    width: 100%;
    min-height: calc(100vh - 100px);
    padding: 30px 10%;
    background-color: #f5efef;
    justify-content: center;
    align-items: center;
}

@media (max-width: 996px) {
    .payment_verification_app {
        padding: 30px 5%;
        align-items: flex-start;
    }
}

.payment_verification_app .payment_verification_container {
    display: flex;
    width: 500px;
    padding: 30px 30px;
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.12);
    flex-direction: column;
    align-items: center;
}

.payment_verification_app .payment_verification_container > h2 {
    font-size: 22px;
    font-weight: 600;
    color: #090e3c;
}

.payment_verification_app .payment_verification_container .loader {
    width: 100%;
    margin-top: 20px;
}

.payment_verification_app .payment_verification_container p {
    padding: 20px 0;
    font-size: 15px;
    text-align: center;
}