.main_section {
    width: 100%;
}

.main_section .slider {
    width: 100%;
    height: 100vh;
}

.main_section .slider .slide {
    position: relative;
}

.main_section .slider .slide img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.demo_slider {
    position: relative;
    width: 100%;
    height: 100vh;
}

.demo_slider img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

@media (max-width: 996px) {
    .demo_slider {
        height: 35vh;
    }

    .main_section .slider {
        height: 35vh;
    }
}

.led_section {
    width: 100%;
    padding: 100px 10%;
}

.led_section .container {
    position: relative;
    width: 100%;
    background-color: #000;
    border-radius: 15px;
    padding: 50px 35px;
    overflow: hidden;
}

.led_section .container > .bk {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    opacity: .8;
    z-index: 1;
}

.led_section .container > .bk.responsive {
    display: none;
}

.led_section .container .content {
    position: relative;
    z-index: 9;
}

.led_section .container h1 {
    font-family: 'RaceSport';
    color: #f22c2d;
    font-size: 72px;
    line-height: 1;
}

.led_section .container h2 {
    color: #fff;
    font-size: 22px;
    margin-top: 20px;
}

.led_section .container p {
    width: 50%;
    color: #fff;
    text-align: justify;
    margin-top: 20px;
    font-size: 18px;
    font-size: 500;
}

.led_section .container a {
    width: fit-content;
    display: block;
    margin-top: 20px;
    color: #000;
    background-color: #fff;
    font-size: 22px;
    font-weight: 600;
    padding: 10px 35px;
    border-radius: 75px;
    text-transform: uppercase;
}

@media (max-width: 996px) {
    .led_section {
        padding: 50px 5%;
    }
    
    .led_section .container {
        padding: 30px 35px;
    }
    
    .led_section .container > .bk {
        display: none;
    }
    
    .led_section .container > .bk.responsive {
        display: block;
        opacity: .5;
    }

    .led_section .container h1 {
        width: 100%;
        text-align: center;
        font-size: 40px;
    }
    
    .led_section .container h2 {
        width: 100%;
        font-size: 16px;
        margin-top: 20px;
        text-align: center;
    }
    
    .led_section .container p {
        width: 100%;
        margin-top: 20px;
        font-size: 16px;
        text-align: center;
    }
    
    .led_section .container a {
        margin-top: 70px;
        font-size: 18px;
        padding: 7px 60px;
        margin-left: auto;
        margin-right: auto;
    }
}

.categories_section {
    width: 100%;
    padding: 100px 10%;
    padding-top: 0;
}

.categories_section .container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.categories_section .container .item {
    position: relative;
    width: calc(50% - 10px);
    height: 340px;
    background-color: #000;
    border-radius: 15px;
    margin-bottom: 20px;
    padding: 20px 30px;
    overflow: hidden;
}

.categories_section .container .item .bk {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    opacity: .5;
    z-index: 1;
}

.categories_section .container .item .content {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 9;
}

.categories_section .container .item .content h2 {
    color: #fff;
    font-size: 22px;
    font-weight: 600;
}

.categories_section .container .item .content p {
    width: 100%;
    font-size: 18px;
    color: #fff;
    text-align: justify;
    margin-top: 20px;
}

.categories_section .container .item .content a {
    display: block;
    font-size: 18px;
    color: #fff;
    margin-top: auto;
}

.categories_section > a {
    display: block;
    width: fit-content;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    background-color: #000037;
    padding: 12px 35px;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

@media (max-width: 996px) {
    .categories_section {
        padding: 50px 5%;
        padding-top: 0;
    }
    
    .categories_section .container .item {
        width: 100%;
        height: auto;
        padding: 30px 30px;
    }
    
    .categories_section .container .item .bk {
        opacity: .4;
    }
    
    .categories_section .container .item .content h2 {
        font-size: 18px;
        text-align: center;
    }
    
    .categories_section .container .item .content p {
        font-size: 16px;
        text-align: center;
    }
    
    .categories_section .container .item .content a {
        font-size: 18px;
        margin-top: 20px;
        text-align: center;
    }
    
    .categories_section > a {
        padding: 10px 35px;
    }
}

.volvo_section {
    width: 100%;
    padding: 100px 10%;
    background-color: #01063b;
}

.volvo_section .content {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
}

.volvo_section .content .content_text h2 {
    color: #fff;
    font-size: 40px;
    font-weight: 700;
}

.volvo_section .content .content_text h2 span {
    font-weight: 500;
}

.volvo_section .content .content_text .divider {
    width: 90%;
    height: 1px;
    background-color: #fff;
    margin: 20px 0;
}

.volvo_section .content .content_text p {
    width: 90%;
    text-align: justify;
    color: #fff;
    font-size: 18px;
}

.volvo_section .content .content_text a {
    display: block;
    width: fit-content;
    color: #01063b;
    background-color: #fff;
    font-size: 18px;
    font-weight: 600;
    padding: 10px 35px;
    border-radius: 15px;
    margin-top: 20px;
}

.volvo_section .content .content_media {
    display: flex;
    justify-content: flex-end;
}

.volvo_section .content .content_media .image {
    position: relative;
    width: 90%;
    height: 100%;
    background-color: #fff;
    border-radius: 15px;
    overflow: hidden;
}

.volvo_section .content .content_media .image img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

@media (max-width: 996px) {
    .volvo_section {
        padding: 50px 5%;
    }
    
    .volvo_section .content {
        grid-template-columns: 1fr;
    }
    
    .volvo_section .content .content_text h2 {
        width: 100%;
        text-align: center;
        font-size: 35px;
    }
    
    .volvo_section .content .content_text .divider {
        width: 100%;
    }
    
    .volvo_section .content .content_text p {
        width: 100%;;
        font-size: 16px;
    }
    
    .volvo_section .content .content_text a {
        font-size: 18px;
        padding: 7px 50px;
        margin-left: auto;
        margin-right: auto;
    }
    
    .volvo_section .content .content_media {
        height: 270px;
        padding-top: 20px;
        justify-content: center;
    }
    
    .volvo_section .content .content_media .image {
        width: 100%;
    } 
}

.services_section {
    padding: 100px 10%;
    padding-bottom: 0;
}

.services_section > h2 {
    display: block;
    font-size: 57px;
    line-height: 1;
    font-family: 'RaceSport';
    color: #f22c2d;
    text-align: center;
    margin-bottom: 70px;
}

.services_section > h2 span {
    font-family: 'RaceSport';
    margin-left: 120px;
}

.services_section .service {
    width: 100%;
    display: grid;
    grid-template-columns: 40% 60%;
    margin-bottom: 100px;
}

.services_section .service .content {
    display: flex;
    justify-content: flex-end;
    padding: 30px 0;
}

.services_section .service .content .container {
    width: 90%;
    min-height: 230px;
    background-color: #01063b;
    border-radius: 15px;
    padding: 25px 30px;
}

.services_section .service .content .container h2 {
    color: #fff;
    font-size: 22px;
    font-weight: 600;
}

.services_section .service .content .container .divider {
    width: 100%;
    height: 1px;
    background-color: #fff;
    margin: 20px 0;
}

.services_section .service .content .container p {
    color: #fff;
    font-size: 18px;
    text-align: justify;
}

.services_section .service .service_img .container {
    width: 90%;
    height: 100%;
}

.services_section .service .service_img .container img {
    width: 100%;
}

@media (max-width: 996px) {
    .services_section {
        padding: 50px 5%;
        padding-bottom: 0;
    }

    .services_section > h2 {
        font-size: 30px;
        margin-bottom: 70px;
    }

    .services_section > h2 span {
        margin-left: 0;
    }

    .services_section .service {
        grid-template-columns: 1fr;
        margin-bottom: 50px;
    }

    .services_section .service .content {
        justify-content: center;
        padding: 30px 0;
    }

    .services_section .service .content .container {
        width: 100%;
        padding: 30px 30px;
    }

    .services_section .service .content .container h2 {
        font-size: 18px;
        text-align: center;
    }

    .services_section .service .content .container p {
        font-size: 16px;
        text-align: center;
    }

    .services_section .service .service_img .container {
        width: 100%;
    }
}

.location_section {
    position: relative;
    width: 100%;
    padding: 100px 10%;
    background-color: #01063b;
}

.location_section > h2 {
    display: block;
    font-size: 55px;
    line-height: 1;
    font-family: 'RaceSport';
    color: #f22c2d;
    margin-bottom: 40px;
}

.location_section > h2 span {
    font-family: 'RaceSport';
    margin-left: 50px;
}

.location_section .container {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    padding-top: 30px;
    z-index: 9;
}

.location_section .container .content p {
    width: 90%;
    color: #fff;
    font-size: 18px;
    text-align: justify;
}

.location_section .container .content a {
    display: block;
    width: fit-content;
    margin-top: 20px;
    text-transform: uppercase;
    color: #01063b;
    background-color: #fff;
    font-weight: 600;
    font-size: 18px;
    padding: 10px 35px;
    border-radius: 15px;
}

.location_section .container .map {
    display: flex;
    justify-content: flex-end;
}

.location_section .container .map .map_container {
    position: relative;
    width: 90%;
    height: 100%;
    min-height: 300px;
    background-color: #fff;
    border-radius: 15px;
    overflow: hidden;
}

.location_section .container .map .map_container iframe {
    width: 100%;
    height: 100%;
}

.location_section .car {
    position: absolute;
    width: 20%;
    left: 0;
    bottom: 0;
    z-index: 1;
}

@media (max-width: 996px) {
    .location_section {
        padding: 50px 5%;
    }
    
    .location_section > h2 {
        font-size: 30px;
        margin-bottom: 20px;
        text-align: center;
    }
    
    .location_section > h2 span {
        margin-left: 0;
    }
    
    .location_section .container {
        grid-template-columns: 1fr;
        padding-top: 10px;
    }
    
    .location_section .container .content p {
        width: 100%;
        font-size: 16px;
    }
    
    .location_section .container .content a {
        font-size: 18px;
        padding: 7px 50px;
        margin-left: auto;
        margin-right: auto;
    }
    
    .location_section .container .map {
        padding-top: 20px;
    }
    
    .location_section .container .map .map_container {
        width: 100%;
        height: 100%;
    }
    
    .location_section .car {
        width: 50%;
    }
}

.about_section {
    width: 100%;
    padding: 100px 10%;
}

.about_section .container {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
}

.about_section .container .title {
    display: flex;
    justify-content: center;
    padding-right: 10%;
    padding-bottom: 20px;
}

.about_section .container .title h2 {
    text-align: center;
    line-height: 1;
    font-size: 56px;
    font-family: 'RaceSport';
    color: #f22c2d;
}

.about_section .container .text_col {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 100px 0;
}

.about_section .container .text_col p {
    width: 90%;
    font-size: 18px;
    text-align: justify;
    color: #01063b;
    font-weight: 500;
}

.about_section .container .img_col {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 500px;
}

.about_section .container .img_col .image {
    position: relative;
    width: 95%;
    height: 100%;
    border-radius: 15px;
    overflow: hidden;
}

.about_section .container .img_col .image img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

}

@media (max-width: 996px) {
    .about_section {
        padding: 50px 5%;
    }
    
    .about_section .container {
        grid-template-columns: 1fr;
    }
    
    .about_section .container .title {
        padding-right: 0;
        padding-bottom: 20px;
    }
    
    .about_section .container .title h2 {
        font-size: 30px;
    }
    
    .about_section .container .text_col {
        justify-content: center;
        padding: 0 0;
        grid-row: 2;
    }
    
    .about_section .container .text_col p {
        width: 100%;
        font-size: 16px;
    }
    
    .about_section .container .img_col {
        justify-content: center;
        min-height: 300px;
        padding-top: 20px;
    }
    
    .about_section .container .img_col .image {
        width: 100%;
    }
}

.sistecredito_section {
    width: 100%;
    padding: 0 10%;
}

.sistecredito_section .container {
    background-color: #01063b;
    padding: 50px 10%;
    padding-bottom: 0;
    border-top-left-radius: 200px;
    border-bottom-right-radius: 200px;
    display: grid;
    grid-template-columns: 55% 45%;
    overflow: hidden;
}

.sistecredito_section .container .media {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.sistecredito_section .container .media img {
    width: 100%;
}

.sistecredito_section .container .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}

.sistecredito_section .container .content img {
    width: 50%;
    margin-top: 20px;
}

.sistecredito_section .container .content h2 {
    color: #fff;
    font-size: 45px;
}

.sistecredito_section .container .content h2 span {
    font-weight: 500;
}

.sistecredito_section .container .content .t2 {
    padding-left: 20px;
    line-height: 1;
}

@media (max-width: 996px) {
    .sistecredito_section {
        padding: 0 5%;
    }
    
    .sistecredito_section .container {
        background-color: #01063b;
        padding: 50px 5%;
        padding-bottom: 0;
        border-top-left-radius: 100px;
        border-bottom-right-radius: 100px;
        grid-template-columns: 1fr;
    }
    
    .sistecredito_section .container .media {
        padding-top: 30px;
        justify-content: center;
    }
    
    .sistecredito_section .container .media img {
        width: 70%;
    }
    
    .sistecredito_section .container .content {
        align-items: center;
    }
    
    .sistecredito_section .container .content h2 {
        font-size: 25px;
        text-align: center;
    }
    
    .sistecredito_section .container .content .t2 {
        padding-left: 0;
    }
}

.testimonial_section {
    width: 100%;
    padding: 50px 10%;
    padding-top: 0px;
}

.testimonial_section > h2 {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 50px;
    color: #cc0000;
    margin-bottom: 30px;
}

.testimonial_section .slide_item {
    width: 100%;
    height: 200px;
    background-color: #01063b !important;
    border-radius: 15px;
    padding: 15px 30px;
}

.testimonial_section .slide_item h2 {
    color: #fff;
    font-size: 19px;
    font-weight: 500;
}

.testimonial_section .slide_item .stars {
    display: flex;
    padding: 5px 0;
}

.testimonial_section .slide_item .stars i {
    margin-right: 5px;
    color: #ffcd00;
}

.testimonial_section .slide_item p {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    text-align: justify;
    margin-top: 10px;
}

@media (max-width: 996px) {
    .testimonial_section .slide_item {
        min-height: 200px;
        height: fit-content;
    }
        
    .testimonial_section {
        padding: 50px 5%;
        padding-top: 0px;
    }

    .testimonial_section > h2 {
        font-size: 35px;
    }

    .testimonial_section .slide_item p {
        text-align: center;
    }
}