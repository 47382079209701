.purchases_app {
    width: 100%;
    min-height: calc(100vh - 100px);
    background-color: #f5efef;
}

.purchases_app .body {
    display: flex;
    width: 100%;
    min-height: calc(100vh - 100px);
}

.purchases_app .purchases_app_content {
    flex: 1;
    padding: 50px 5%;
}

@media (max-width: 996px) {
    .purchases_app .body {
        flex-direction: column;
    }
    
    .purchases_app .purchases_app_content {
        flex: 1;
        padding: 50px 5%;
    }
}

