.login_app_body {
    position: relative;
    width: 100%;
    min-height: 100vh;
    background-color: #f5efef;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
}

.login_app_body .header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50vh;
    background-color: #090e3c;
}

@media (max-width: 996px) {
    .login_app_body {
        padding: 80px 0;
        padding-bottom: 30px;
    }

    .login_app_body .header {
        width: 100%;
        height: 100%;
    }
}

.login_app_body .header .body {
    height: 70px;
    display: flex;
    padding: 0 10%;
    align-items: center;
    justify-content: space-between;
}

.login_app_body .header .logo {
    height: 70%;
}

.login_app_body .header .logo img {
    height: 100%;
}

@media (max-width: 996px) {
    .login_app_body .header .logo {
        height: 50%;
    }    
}

.login_app_body .form {
    padding: 40px 50px;
    position: relative;
    width: 500px;
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.12);
    display: flex;
    flex-direction: column;
}

.login_app_body .form h1 {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: rgba(0,0,0,.9);
    letter-spacing: 1px;
    margin-bottom: 30px;
}

@media (max-width: 996px) {
    .login_app_body .form {
        padding: 30px 20px;
        width: 90%;
    }

    .login_app_body .form h1 {
        font-size: 20px;
    }
}

.login_app_body .form .fancy_input {
    margin-bottom: 20px;
}

.login_app_body .form .fancy_input label {
    font-size: 16px;
}

.login_app_body .form .fancy_input input {
    padding: 8px 15px;
}

.login_app_body .form .forgot {
    font-size: 14px;
    margin-bottom: 20px;
}

.login_app_body .form .login_btn {
    margin-top: auto;
    height: 45px;
    border: none;
    background-color: #090e3c;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    border-radius: 5px;
    cursor: pointer;
}

.login_app_body .form .signup_btn {
    margin-top: 5px;
    height: 45px;
    border: none;
    background-color: #fff;
    color: #090e3c;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
}

.login_app_body .form .loader {
    margin-bottom: 10px;
}

.login_app_body .form .error_msg {
    margin-bottom: 20px;
}