.shopping_cart_summary {
    min-width: 30%;
    max-width: 30%;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.12);
}

@media (max-width: 996px) {
    .shopping_cart_summary {
        min-width: 100%;
        max-width: 100%;
        margin-bottom: 20px;
    }
}

.shopping_cart_summary .header {
    padding: 7px 20px;
    border-bottom: 1px solid #d8d8d8;
}

.shopping_cart_summary .header h2 {
    font-size: 18px;
    font-weight: 500;
}

.shopping_cart_summary .body {
    padding: 15px 20px;
}

.shopping_cart_summary .body .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 0;
}

.shopping_cart_summary .body .item h2 {
    font-size: 14px;
    font-weight: 400;
}

.shopping_cart_summary .body .item span {
    font-size: 14px;
}

.shopping_cart_summary .body .item.total {
    margin-top: 5px;
}

.shopping_cart_summary .body .item.total h2 {
    font-size: 18px;
    font-weight: 600;
}

.shopping_cart_summary .body .item.total span {
    font-size: 18px;
}

.shopping_cart_summary .body button {
    width: 100%;
    padding: 10px 0;
    margin-top: 20px;
}