.delete_address_modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, .7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 501;
}

.delete_address_modal .container {
    width: 40%;
    background-color: #fff;
    border-radius: 5px;
}

@media (max-width: 996px) {
    .delete_address_modal .container {
        width: 90%;
    }    
}

.delete_address_modal .container .header {
    padding: 10px 20px;
    border-bottom: 1px solid #d8d8d8;
}

.delete_address_modal .container .header h2 {
    font-weight: 500;
    font-size: 18px;
}

.delete_address_modal .container .body {
    padding: 20px;
}

.delete_address_modal .container .body p {
    font-size: 16px;
}

@media (max-width: 996px) {
    .delete_address_modal .container .body p {
        font-size: 14px;
        text-align: center;
    }
}

.delete_address_modal .container .body .loader {
    margin-top: 20px;
}

.delete_address_modal .container .body .error_msg {
    margin-bottom: 20px;
}

.delete_address_modal .container .footer {
    padding: 10px 20px;
    border-top: 1px solid #d8d8d8;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.delete_address_modal .container .footer .btns button {
    margin-left: 10px;
}