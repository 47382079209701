.single_product_body {
    padding: 50px 10%;
}

@media (max-width: 996px) {
    .single_product_body {
        padding: 30px 5%;
    } 
}

.single_product_body .main_info_container {
    padding: 20px;
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0px 0px 40px -25px rgba(0,0,0,0.2);
}

.single_product_body .main_info_container .product_main {
    width: 100%;
    display: grid;
    grid-template-columns: 60% 40%;
}

@media (max-width: 996px) {
    .single_product_body .main_info_container .product_main {
        grid-template-columns: 1fr;
    }
}

.single_product_body .main_info_container .product_main .product_image {
    display: flex;
    flex-direction: column;
}

.single_product_body .main_info_container .product_main .product_image .current_image {
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;
    padding-top: 56.25%;
    box-shadow: 0px 0px 40px -25px rgba(0,0,0,0.2);
    border-radius: 7px;
    border: 1px solid #c2c2c2;
    overflow: hidden;
}

.single_product_body .main_info_container .product_main .product_image .current_image img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.single_product_body .main_info_container .product_main .product_image .current_image img.blur {
    top: -5%;
    left: -5%;
    width: 110%;
    height: 110%;
    object-fit: cover;
    filter: blur(10px);
}

.single_product_body .main_info_container .product_main .product_information {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
}

@media (max-width: 996px) {
    .single_product_body .main_info_container .product_main .product_information {
        padding: 20px 0;
        padding-bottom: 10px;
    }
}

.single_product_body .main_info_container .product_main .product_information .name {
    font-size: 22px;
    color: rgba(0,0,0,0.9);
    word-wrap: break-word;
    white-space: wrap;
    margin-bottom: 8px;
    font-weight: 600;
}

.single_product_body .main_info_container .product_main .product_information .price_before {
    position: relative;
    width: fit-content;
    font-size: 16px;
    font-weight: 300;
    color: #adadad;
}

.single_product_body .main_info_container .product_main .product_information .price_before::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
    transform: translateY(-50%);
    background-color: rgba(0,0,0,.6);
}

.single_product_body .main_info_container .product_main .product_information .price {
    display: flex;
    align-items: center;
}

.single_product_body .main_info_container .product_main .product_information .price span {
    font-size: 36px;
    font-weight: 300;
    color: #181818;
}

.single_product_body .main_info_container .product_main .product_information .price p {
    margin-left: 10px;
    color: #28a745;
    font-size: 18px;
}

.single_product_body .main_info_container .product_main .product_information .qty_input_container {
    width: fit-content;
}

.single_product_body .main_info_container .product_main .product_information .actions {
    display: flex;
    flex-direction: column;
    margin-top: auto;
}

.single_product_body .main_info_container .product_main .product_information .actions button {
    height: 40px;
    margin-top: 10px;
    font-size: 16px;
    border-radius: 7px;
    border: none;
    cursor: pointer;
}

.single_product_body .main_info_container .product_main .product_information .actions .add {
    background-color: #090e3c;
    color: #fff;
}

.single_product_body .main_info_container .product_description {
    padding: 20px 0;
}

.single_product_body .main_info_container .product_description > h2 {
    font-size: 22px;
    font-weight: 500;
    color: rgba(0,0,0,.9);
}

.single_product_body .main_info_container .product_description p {
    width: 60%;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    margin-top: 8px;
    color: #666;
}

@media (max-width: 996px) {
    .single_product_body .main_info_container .product_description > h2 {
        font-size: 18px;
    }

    .single_product_body .main_info_container .product_description p {
        width: 100%;
        font-size: 14px;
    }
}

.single_product_body .links {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.single_product_body .links .back {
    font-size: 14px;
}

.single_product_body .qty_input_container {
    margin-top: 10px;
}