.login_modal {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .6);
    z-index: 401;
}

.login_modal .form {
    position: relative;
    width: 500px;
    padding: 40px 50px;
    position: relative;
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,.12);
    display: flex;
    flex-direction: column;
}

@media (max-width: 996px) {
    .login_modal {
        align-items: flex-start;
        padding-top: 100px;
    }

    .login_modal .form {
        width: 90%;
        padding: 40px 30px;
    }
}

.login_modal .form .close {
    position: absolute;
    padding: 5px 15px;
    top: 0;
    right: 10px;
    font-size: 20px;
    border: none;
    background-color: transparent;
    color: #090e3c;
    cursor: pointer;
}

.login_modal .form img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.login_modal .form h2 {
    text-align: center;
    font-size: 19px;
    font-weight: 500;
    color: rgba(0,0,0,.9);
    letter-spacing: 1px;
    margin-bottom: 25px;
}

.login_modal .form .fancy_input {
    margin-bottom: 10px;
}

.login_modal .form .fancy_input label {
    font-size: 14px;
}


.login_modal .form button {
    margin-top: 10px;
}