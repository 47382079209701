.category_filter .input {
    display: flex;
    padding: 1px 0;
}

.category_filter .input label {
    display: block;
    font-size: 14px;
    margin-left: 5px;
    word-break: break-all;
}
