.profile_app {
    width: 100%;
    min-height: calc(100vh - 100px);
    background-color: #f5efef;
}

.profile_app .body {
    display: flex;
    width: 100%;
    min-height: calc(100vh - 100px);
}

.profile_app .profile_app_content {
    flex: 1;
    padding: 50px 10%;
}

@media (max-width: 996px) {
    .profile_app .body {
        flex-direction: column;
    }
    
    .profile_app .profile_app_content {
        flex: 1;
        padding: 50px 5%;
    }
}

.profile_app .profile_app_content .profile_container {
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 2px 1px rgba(0,0,0,.12);
    margin-bottom: 15px;
}

.profile_app .profile_app_content .profile_container .header {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #d8d8d8;
}

.profile_app .profile_app_content .profile_container .header h2 {
    font-size: 18px;
    font-weight: 500;
}

.profile_app .profile_app_content .profile_container .profile_body {
    padding: 20px 20px;
}

.profile_app .profile_app_content .profile_container .profile_body .fancy_input_group {
    margin-bottom: 10px;
}

.profile_app .profile_app_content .profile_container .profile_body .loader {
    margin-top: 30px;
}

.profile_app .profile_app_content .profile_container .profile_body .actions {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile_app .profile_app_content .profile_container .profile_body .error_msg {
    margin-bottom: 10px;
}