.responsive_store_header_logo {
    display: none;
    padding: 10px 5%;
    padding-right: calc(5% + 40px);
    width: 100%;
    height: 70px;
    align-items: center;
    justify-content: space-between;
    background-color: #090e3c;
}

@media (max-width: 996px) {
    .responsive_store_header_logo {
        display: flex;
    }
}

.responsive_store_header_logo .logo {
    height: 60%;
}

.responsive_store_header_logo .logo img {
    height: 100%;
}

.responsive_store_header_logo .link {
    font-size: 16px;
    color: #fff;
}

.store_header_mobile {
    display: none;
    width: 100%;
    height: 60px;
    padding: 10px 5%;
    background-color: #090e3c;
    align-items: center;
    justify-content: center;
}

@media (max-width: 996px) {
    .store_header_mobile {
        display: flex;
    }
}

.store_header_mobile .cart {
    width: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
}

.search_container {
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: stretch;
    overflow: hidden;
    flex: 1;
}

.search_container input {
    width: 100%;
    border: none;
    outline: none;
    padding: 5px 15px;
}

.search_container .btn {
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    justify-content: flex-end;
    align-items: center;
    color: #090e3c;
    font-size: 14px;
}

.store_header_mobile_menu {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 100vh;
    padding: 20px;
    top: 0;
    right: 0;
    transform: translateX(100%);
    background-color: #090e3c;
    transition: .25s;
    z-index: 399;
}

.store_header_mobile_menu.active {
    transform: translateX(0);
}

.store_header_mobile_menu .logo {
    width: 100%;
    margin-bottom: 20px;
}

.store_header_mobile_menu .logo img {
    width: 80%;
}

.store_header_mobile_menu .item {
    color: #fff;
    font-size: 16px;
    padding: 10px 0;
}

.store_header_mobile_menu .close {
    display: none;
    position: absolute;
    top: 15px;
    left: -10px;
    width: 40px;
    height: 40px;
    background-color: #090e3c;
    border: none;
    border-radius: 15px;
    outline: none;
    transform: translateX(-100%);
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 18px;
}

@media (max-width: 996px) {
    .store_header_mobile_menu .close {
        display: flex;
    }
}

.store_header_mobile_menu.active {
    transform: translateX(0);
}

.store_header_mobile_menu .close #nav-icon1 {
    width: 20px;
    height: 18px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}
  
.store_header_mobile_menu .close #nav-icon1 span{
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #fff;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}
  
.store_header_mobile_menu .close #nav-icon1 span:nth-child(1) {
    top: 0px;
}
  
.store_header_mobile_menu .close #nav-icon1 span:nth-child(2) {
    top: 9px;
}
  
.store_header_mobile_menu .close #nav-icon1 span:nth-child(3) {
    top: 18px;
}
  
.store_header_mobile_menu .close #nav-icon1.open span:nth-child(1) {
    top: 9px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}
  
.store_header_mobile_menu .close #nav-icon1.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
}
  
.store_header_mobile_menu .close #nav-icon1.open span:nth-child(3) {
    top: 9px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

.store_header_backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, .6);
    backdrop-filter: blur(5px);
    visibility: hidden;
    opacity: 0;
    transition: .25s;
    z-index: 381;
}

.store_header_backdrop.active {
    visibility: visible;
    opacity: 1;
}
