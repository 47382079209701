.webpage_footer {
    position: relative;
    width: 100%;
    padding: 100px 10%;
    background-color: #01063b;
}

.main_footer {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 45% 55%;
    z-index: 9;
}

.main_footer .logo { 
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
}

.main_footer .logo a {
    width: 40%;
}

.main_footer .logo img {
    width: 100%;
}

.main_footer .map {
    display: flex;
}

.main_footer .map .map_container {
    width: 100%;
    min-height: 250px;
    background-color: #fff;
    border-radius: 15px;
    overflow: hidden;
}

.main_footer .map .map_container iframe {
    width: 100%;
    height: 100%;
}

.main_footer .content {
    padding-left: 30px;
    display: flex;
    flex-direction: column;
}

.main_footer .content > a {
    font-size: 18px;
    color: #fff;
    margin-bottom: 10px;
}

.main_footer .content > a i {
    margin-right: 10px;
}

.main_footer .content .social {
    display: flex;
    margin-top: auto;
}

.main_footer .content .social a {
    width: 35px;
    height: 35px;
    background-color: #fff;
    margin-right: 10px;
    border-radius: 50%;
    color: #01063b;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}

.footer_nav {
    position: relative;
    width: 100%;
    padding-top: 50px;
    display: flex;
    justify-content: space-between;
    z-index: 9;
}

.footer_nav .nav_group {
    display: flex;
    flex-direction: column;
}

.footer_nav .nav_group h2 {
    font-size: 20px;
    color: #fff;
    font-weight: 500;
}

.footer_nav .nav_group a {
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    padding: 5px 0;
}

.footer_nav .nav_group input {
    width: 350px !important;
    height: 40px;
    font-size: 15px;
    padding: 0 15px;
    border-radius: 15px;
    margin-top: 10px;
    border: none;
}

.footer_nav .nav_group .suscribe_disclaimer {
    max-width: 350px;
    font-size: 10px;
    color: #fff;
    margin-top: 10px;
}

.footer_nav .nav_group .suscribe_disclaimer a {
    font-size: 10px;
    text-decoration: underline;
}

.footer_nav .nav_group .suscribe_btn {
    width: fit-content;
    color: #01063b;
    background-color: #ffdc40;
    font-size: 18px;
    font-weight: 600;
    padding: 3px 45px;
    border-radius: 15px;
    margin-top: 10px;
    cursor: pointer;
    border: none;
}

footer .car {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 70%;
}

footer .copyright_bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
}

footer .copyright_bar a {
    color: #fff;
    font-size: 14px;
}

@media (max-width: 996px) {
    .webpage_footer {
        padding: 50px 5%;
    }
    
    .main_footer {
        grid-template-columns: 1fr;
    }
    
    .main_footer .logo { 
        padding-bottom: 20px;
    }
    
    .main_footer .logo a {
        width: 70%;
    }
    
    .main_footer .map .map_container {
        min-height: 250px;
    }
    
    .main_footer .content {
        padding-left: 0;
        padding-top: 20px;
    }
    
    .main_footer .content > a {
        font-size: 16px;
    }
    
    .main_footer .content .social {
        margin-top: 20px;
    }
    
    .main_footer .content .social a {
        width: 45px;
        height: 45px;
        font-size: 22px;
    }
    
    .footer_nav {
        padding-top: 50px;
        display: flex;
        flex-direction: column-reverse;
    }
    
    .footer_nav .nav_group {
        align-items: center;
        margin-bottom: 20px;
    }
    
    .footer_nav .nav_group h2 {
        font-size: 18px;
    }
    
    .footer_nav .nav_group a {
        font-size: 12px;
        text-align: center;
    }
    
    .footer_nav .nav_group input {
        width: 100% !important;
    }
    
    .footer_nav .nav_group .suscribe_disclaimer {
        max-width: 100%;
    }
    
    .footer_nav .nav_group .suscribe_btn {
        padding: 5px 55px;
        border-radius: 15px;
    }
    
    footer .car {
        display: none;
    }
}